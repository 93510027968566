.ph1 {
  font-size: 3rem !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph2 {
  font-size: 2.25rem !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph3 {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph4 {
  font-size: 1.125rem !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph5 {
  font-size: 1rem !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph6 {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph7 {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph8 {
  font-size: 0.625rem !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph9 {
  font-size: 96px !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph10 {
  font-size: 64px !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph11 {
  font-size: 2rem !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph12 {
  font-size: 1.75rem !important;
  font-weight: 400 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph1-semibold {
  font-size: 3rem !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph2-semibold {
  font-size: 2.25rem !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph3-semibold {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph4-semibold {
  font-size: 1.125rem !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph5-semibold {
  font-size: 1rem !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph6-semibold {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph7-semibold {
  font-size: 0.75rem !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph8-semibold {
  font-size: 0.625rem !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph9-semibold {
  font-size: 96px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph10-semibold {
  font-size: 64px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph11-semibold {
  font-size: 2rem !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph12-semibold {
  font-size: 1.75rem !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.semibold {
  font-weight: 600 !important;
}

.ph1-bold {
  font-size: 3rem !important;
  font-weight: 700 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph2-bold {
  font-size: 2.25rem !important;
  font-weight: 700 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph3-bold {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph4-bold {
  font-size: 1.125rem !important;
  font-weight: 700 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph5-bold {
  font-size: 1rem !important;
  font-weight: 700 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph6-bold {
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph7-bold {
  font-size: 0.75rem !important;
  font-weight: 700 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph8-bold {
  font-size: 0.625rem !important;
  font-weight: 700 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph9-bold {
  font-size: 96px !important;
  font-weight: 700 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph10-bold {
  font-size: 64px !important;
  font-weight: 700 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.bold {
  font-weight: 600 !important;
}

.ph11-bold {
  font-size: 2rem !important;
  font-weight: 700 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph12-bold {
  font-size: 1.75rem !important;
  font-weight: 700 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph1-lite {
  font-size: 3rem !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph2-lite {
  font-size: 2.25rem !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph3-lite {
  font-size: 1.5rem !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph4-lite {
  font-size: 1.125rem !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph5-lite {
  font-size: 1rem !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph6-lite {
  font-size: 0.875rem !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph7-lite {
  font-size: 0.75rem !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}
.ph8-lite {
  font-size: 0.625rem !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph9-lite {
  font-size: 96px !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph10-lite {
  font-size: 64px !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph11-lite {
  font-size: 2rem !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

.ph12-lite {
  font-size: 1.75rem !important;
  font-weight: 300 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
}

/* pickle text color */
.ptc0 {
  color: #000 !important;
}

.ptc1 {
  color: #1e212e !important;
}

.ptc2 {
  color: #fff !important;
}

.ptc3 {
  color: #363d57 !important;
}

.ptc4 {
  color: #e76f51 !important;
}

.ptc5 {
  color: #6c7596 !important;
}

.ptc6 {
  color: #e74b5f !important;
}

.ptc7 {
  color: #2dccb6 !important;
}

.ptc8 {
  color: #606781 !important;
}

.ptc9 {
  color: #14161d !important;
}

.ptc10 {
  color: #9013fe !important;
}

.ptc11 {
  color: #363d57 !important;
}

.ptc12 {
  color: #4264f5 !important;
}

.ptc13 {
  color: #11131b !important;
}

.ptc14 {
  color: #5c627d !important;
}

.ptc15 {
  color: #383d52 !important;
}

.ptc16 {
  color: #d4dbf5 !important;
}

.ptc17 {
  color: #e95a56 !important;
}

.ptc18 {
  color: #179e4a !important;
}

.ptc19 {
  color: #b2b2b2 !important;
}

.ptc20 {
  color: #a9a8b4 !important;
}
.ph-description {
  line-height: 1.7 !important;
}

.w-10 {
  width: 10% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}

.w-fit {
  width: fit-content !important;
}

.h-fit {
  width: fit-content !important;
}

.ph-description {
  line-height: 1.7 !important;
}
.w-60 {
  width: 60% !important;
}
.w-70 {
  width: 70% !important;
}
.w-80 {
  width: 80% !important;
}
.w-90 {
  width: 90% !important;
}
