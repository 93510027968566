/* You can add global styles to this file, and also import other style files */
/* @tailwind base; */
@tailwind components;
@tailwind utilities;

body {
  font-family: Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
}

.cursor-pointer {
  cursor: pointer !important;
}

.chart-area {
  height: auto;
}

.form-control-admin {
  font-size: 0.9rem;
  border-radius: 10rem;
  /* padding: 1.5rem 1rem; */
}
.admin-form .form-control:focus {
  /* border-color: #ff80ff; */
  /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(255, 100, 255, 0.5); */
}

/* width */
.custom-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f2f2f2;
  border-radius: 10px;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: #5f85d8;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #f37272;
  cursor: pointer;
}

.rounded {
  border-radius: 6px !important;
}

.bg-1 {
  background-color: #fff !important;
}

.bg-2 {
  background-color: #fbfbfb !important;
}

.bg-3 {
  background-color: #f8f9ff !important;
}

.bg-4 {
  background-color: #4264f5 !important;
}

.bg-5 {
  background-color: #f9f8ff !important;
}

.bg-6 {
  background-color: #edf1ff !important;
}

.bg-7 {
  background-color: #ecfbf9 !important;
}
.bg-8 {
  background-color: #ffe5e5 !important;
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_between {
  display: flex;
  justify-content: space-between;
}

.flex_between_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
